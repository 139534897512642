import {Card, Typography} from "@material-tailwind/react";

export function CardStatistics({title, percentage, legend, icon, background, textcolor}) {
    return (
        <Card className={`px-4 py-4 min-h-[155px] rounded-lg ${background}`}>
            {/* HEADER */}
            <div className="w-full pt-2">
                <div className="w-min p-2 rounded-md shadow-md shadow-gray-300 justify-start text-green-800 text-center flex bg-white">
                    {icon}
                </div>
                <Typography className={`pt-2 text-start ${textcolor}`} variant="h5">
                    {title}
                </Typography>
            </div>
            {/* BODY */}
            <div className="w-full">
                <Typography variant="small pb-2" className={`${textcolor} text-opacity-80`}>
                    {legend}
                </Typography>
                <Typography className={textcolor? textcolor : "text-green-800"}
                            variant="h4">
                    {percentage}
                </Typography>
            </div>
        </Card>
    )
}