import {useState, useRef, useEffect} from 'react';
import Container from "../../../components/elements/ContainerComponent"
import {SearchProduct} from './SearchProduct';
import {ProductSelectedComponent} from './ProductSelected';
import {NavLink, useNavigate} from "react-router-dom";
import {setMoney} from '../../../utils/shared'
import {toastError, toastInfo} from "../../../js/toast";
import {
  EllipsisVerticalIcon,
  QueueListIcon,
  ArrowRightOnRectangleIcon,
  ArrowsPointingOutIcon,
} from "@heroicons/react/24/solid";
import {Button, ButtonGroup, IconButton, Menu, MenuHandler, MenuItem, MenuList} from "@material-tailwind/react";
import {PrimaryButton, SecondaryButton} from "../../../components/elements/Inputs/ButtonsComponents";
import {toggleShowSidebar} from "../../../components/layout/Navbar";
import {LoaderEllipsisComponent} from "../../../components/elements/Loader/LoaderComponent";
import {ModalCashRegister} from "./ModalCashRegister";
import {Tooltip} from "recharts";


export function CreateSales(props) {
  const [didMount, setMount] = useState(false);
  const [openModalCashRegister, setOpenModalCashRegister] = useState(false);
  const [closeCashRegister, setCloseCashRegister] = useState(false);
  const [itemSelected, setItemSelected] = useState([]);
  const refNitInput = useRef();
  const nav = useNavigate();
  const {user} = props;

  useEffect(() => {
    hasCashRegister()
    setMount(true)
  }, [])

  useEffect(() => {
    if (didMount && user) {
      hasCashRegister()
    }
  }, [didMount, user])

  const hasCashRegister = () => {
    let localUser = localStorage.getItem('user')
    if (localUser) {
      localUser = JSON.parse(localUser)
      if (!localUser?.cash_register) {
        setOpenModalCashRegister(true)
      }
    } else {
      nav('/')
    }
  }

  const addItemSelectedValidation = (element) => {
    let exist = false;
    let newArray = itemSelected.map((ele) => {
      if (ele.id === element.id && ele.price_type === element.price_type && ele.warehouse?.id === element.warehouse?.id) {
        if (ele.quantity >= element.stock) {
          toastInfo(`Solo existen ${element.stock} de ${ele.name} disponibles`)
          ele.quantity = element.stock;
        } else {
          ele.quantity = parseInt(ele.quantity) + parseInt(element.quantity);
        }
        exist = true;
      }
      return ele
    })
    if (!exist) {
      newArray.push(element)
    }
    setItemSelected(newArray)
  }

  const changeValueItemSelected = (element, quantity) => {
    let newArray = itemSelected.map((ele) => {
      if (ele.id === element.id) ele.quantity = quantity ? quantity : 0;
      return ele
    })
    setItemSelected(newArray)
  }

  const getTotal = (withTag = false) => {
    let total = 0;
    itemSelected.map((item) => {
      total += (item.price * item.quantity)
    })
    if (withTag)
      return total
    else
      return setMoney(total)
  }

  const removeItem = (id) => {
    let newArray = itemSelected.filter((ele) => ele.id !== id)
    setItemSelected(newArray)
  }

  const createSales = () => {
    if (!user?.cash_register) {
      toastError('No tienes una caja aperturada, apertura una o recarga la página')
      return;
    }
    const data = {
      nit: refNitInput.current?.value.length > 0 ? refNitInput.current?.value : "CF",
      sale_details: itemSelected,
      total: getTotal(true),
      branch_office: user.cash_register.branch_office,
      cash_register: user.cash_register.id,
    }
    props.create(data, nav)
  }

  const closeCashAction = () => {
    setCloseCashRegister(true)
    setOpenModalCashRegister(true)
  }


  return (
    <Container
      title="Registrar una venta"
      customButton={
        <ButtonGroup variant="text" size="sm">
          <Button onClick={toggleShowSidebar} className="tooltip-custom">
            <span className="tooltiptext-custom">
              Expadir / Contraer
            </span>
            <ArrowsPointingOutIcon className="w-5 h-5 mr-2"/>
          </Button>
          <Button className="tooltip-custom">
            <NavLink to="/venta/lista" className="flex flex-row items-end">
              <QueueListIcon className="w-5 h-5 mr-2"/>
              <span className="tooltiptext-custom">
              Ver ventas realizadas
             </span>
            </NavLink>
          </Button>
          <Button onClick={closeCashAction} className="tooltip-custom">
            <ArrowRightOnRectangleIcon className="w-5 h-5 mr-2"/>
            <span className="tooltiptext-custom">
              Cerrar caja
            </span>
          </Button>
        </ButtonGroup>
      }>
      <ModalCashRegister
        closeCashRegister={closeCashRegister}
        show={openModalCashRegister}
        loader={props.loaderCashRegister}
        setShow={setOpenModalCashRegister}
        title={"Aperturar caja Registradora"}
        message={"Para poder realizar ventas se debe aperturar una caja registradora."}
      />
      <LoaderEllipsisComponent loader={props.loader}/>
      {
        !user?.cash_register ?
          <div></div> :
          <>
            <div className='grid gap-2 grid-cols-12'>
              <div className='col-span-12 lg:col-span-5 xl:col-span-5  2xl:col-span-5  min-h-full'>
                <SearchProduct
                  {...props}
                  addItem={addItemSelectedValidation}
                />
              </div>
              <div className='col-span-12 lg:col-span-7 xl:col-span-7 2xl:col-span-7  h-full'>
                <ProductSelectedComponent
                  {...props}
                  totalSales={getTotal()}
                  items={itemSelected}
                  removeItem={removeItem}
                  refNitInput={refNitInput}
                  changeValueItemSelected={changeValueItemSelected}/>
              </div>
            </div>
            <div className="flex md:flex-row lg:flex-row flex-col justify-between gap-3 mt-6">
              <SecondaryButton
                type="button"
                text={'Regresar'}
                onClick={() => nav('/venta')}
              />
              <PrimaryButton
                onClick={createSales}
                text={'Finalizar venta'}
                disabled={!itemSelected.length}
              />
            </div>
          </>
      }
    </Container>
  )
}
