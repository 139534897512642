import {useEffect, useState} from "react";
import {Button, Input, Tooltip} from "@material-tailwind/react";
import {MagnifyingGlassIcon} from "@heroicons/react/24/solid";

function SearchInput(props) {
  const {onClickSearch, searchValue} = props;
  const [search, setSearch] = useState("");
  const [mounted, setMount] = useState(false);
  useEffect(() => {
    setMount(true)
  }, [])

  useEffect(() => {
    if (mounted) {
      setSearch(searchValue)
    }
  }, [mounted])

  const onChange = ({target}) => {
    if (target.value === '') {
      onClickSearch('')
    }
    setSearch(target.value)
  };

  return (
    <div className="relative flex w-full">
      <Input
        type="text"
        value={search}
        placeholder="Buscar"
        autoComplete="off"
        onChange={onChange}
        className="pr-24 !mt-0"
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            onClickSearch(search)
          }
        }}
        containerProps={{
          className: "min-w-0",
        }}
      />
      <Tooltip content="Presiona para buscar" placement="top">
        <Button
          size="sm"
          onClick={event => onClickSearch(search)}
          color={search ? "gray" : "gray"}
          disabled={!search}
          className={`!absolute right-[1px] top-1-button rounded-none px-4 py-1.5 ${search && 'bg-blue-gray-100'}`}
        >
          {/*<PaperAirplaneIcon className={`w-4 h-4 rotate-[315deg] ${search ? '' : 'opacity-50'}`}></PaperAirplaneIcon>*/}
          <MagnifyingGlassIcon className={`w-5 h-5 ${search ? '' : 'opacity-50'}`}></MagnifyingGlassIcon>
        </Button>
      </Tooltip>

    </div>
  );
}

export {SearchInput}