import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {required} from "../../../components/elements/Forms/validations";
import {FormComponent} from "../../../components/elements/Forms/FormComponent";
import ContainerComponent from "../../../components/elements/ContainerComponent";
import {WAREHOUSE_STATUS, WarehouseStatus} from "../../../utils/shared";


export function CreateWarehouse(props) {
  const {isView = false, isUpdate = false, item, retrieve, loader} = props;
  const [didMount, setMount] = useState(false)
  const nav = useNavigate();
  const {id} = useParams();

  useEffect(() => {
    setMount(true)
  }, []);
  
  useEffect(() => {
    if (id && didMount){
      retrieve(id);
    }
  }, [id, didMount, retrieve]);


  
  const fields = {
    branch_office: {label: 'Sucursal', type: 'select', urlOptionList: 'branch-offices/select'},
    name: {label: 'Nombre', validation: required()},
    departament: {label: 'Departamento', type: 'select', urlOptionList: 'department/select_option'},
    municipality: {label: 'Municipio', type: 'select', urlOptionList: 'municipality/select_option', dependence: 'departament'},
    status: {label: 'Estado bodega', type: 'select', optionListInit: WarehouseStatus, options: WAREHOUSE_STATUS},
  }

  const handleSubmit = (data) => {
    if(id){
      props.update(id, data, nav)
    } else {
      props.create(data, nav)
    }
  }

  return (
    <>
      <ContainerComponent
        title={isUpdate ? "Editar Bodega" : isView? `Bodega ${id}` : "Crear Bodega"}
      >
        Load
        <FormComponent
          isUpdate={isUpdate}
          isView={isView}
          fields={fields}
          loader={loader}
          data={item?.id? item: {status: 1}}
          actionSubmit={handleSubmit}
          pathBack={'/bodega'}
        />
      </ContainerComponent>
    </>
  )
}