import React, {useEffect, useState} from "react";
import {
    Accordion,
    AccordionBody,
    AccordionHeader, Avatar,
    Button,
    Card,
    List,
    ListItem,
    ListItemPrefix,
    Typography,
} from "@material-tailwind/react";

import {ChevronDownIcon, XMarkIcon,} from "@heroicons/react/24/solid";
import {useLocation, useNavigate} from "react-router-dom";
import Menu from "./Menu";
import {ActionUserComponent} from "../ActionsUser";

export function SidebarComponent(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const [openMenus, setOpenMenus] = useState({});
    const [didMount, setMount] = useState(false);
    const [menuData, setMenu] = useState([]);
    const {data} = props;

    useEffect(() => {
        setMount(true)
        renderViews()
    }, []);

    const renderViews = () => {
        const views = localStorage.getItem('views')
        if (views === 'is_superuser') {
            setMenu(Menu)
        } else {
            const menu = [];
            const jsonViews = JSON.parse(views)
            // recorremos el array de vistas que tiene el usuario
            jsonViews?.forEach(({view_f}) => {
                // Recoremos el array de Menu para validar cuales se activan
                Menu.forEach((item) => {
                    // Si no tiene hijos se compara si los paths coinciden para agregarlo al nuevo menu

                    const split = view_f.split(',');

                    split.forEach((splitItem) => {

                        splitItem = splitItem.trim();
                        if (item._path === splitItem && !item.children) {
                            menu.push(item)
                        } else if (item.children) {
                            // Si tiene hijos se compara si los paths de los hijos coinciden con el item de la vista que se esta evaluando
                            // y si es asi se agrega a un nuevo array de hijos para este item
                            let child = []
                            item.children.forEach((ele) => {
                                if (ele._path === splitItem) {
                                    child.push(ele)
                                }
                            })
                            if (child.length) {
                                const newItem = {
                                    title: item.title,
                                    icon: item.icon,
                                    children: child,
                                }
                                const buttonChildren = menu.find((ele) => ele.title === newItem.title)
                                if (buttonChildren) {
                                    buttonChildren.children = [...buttonChildren.children, ...child]
                                } else {
                                    menu.push(newItem)
                                }
                            }
                        }
                    })
                })
            })
            setMenu(menu)
        }
    }

    useEffect(() => {
        renderViews()
    }, [didMount, data])

    const handleOpenMenu = (index) => {
        setOpenMenus(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    }

    const action = (url) => {
        if (url) navigate(url)
        hiddenSidebar()
    }
    const hiddenSidebar = () => {
        const sidebarContainer = document.getElementById('sidebarContainer')
        sidebarContainer.classList.add('hidden')
        sidebarContainer.classList.remove('absolute')
        sidebarContainer.classList.remove('z-20')
        document.body.classList.remove('overscroll-none')
        document.body.classList.remove('overflow-hidden')
    }

    let validateClassName = (item) => {
        const reg = /\/detalle\/+[0-9]/g;
        const reg2 = /\/crear/g;
        const reg3 = /\/editar\/+[0-9]/g;
        const reg4 = /\/lista/g;
        let rutaActual = location.pathname.replace(reg, '')
        rutaActual = rutaActual.replace(reg2, '')
        rutaActual = rutaActual.replace(reg3, '')
        rutaActual = rutaActual.replace(reg4, '')

        if (item?.children) {
            let classItemAcordion = 'p-0'
            item.children.forEach((child) => {
                if (child._path === rutaActual) {
                    classItemAcordion = 'p-0 bg-white bg-opacity-80 hover:bg-white active:bg-white'
                }
            })
            return classItemAcordion
        }
        return `${rutaActual === item._path ? 'bg-white text-selected-menu' : 'text-blue-gray-700'} focus:bg-white focus:text-selected-menu hover:bg-white hover:text-green-600 active:bg-white active:text-green-600`;
    };

    let user = localStorage.getItem('user')
    if (user) {
        user = JSON.parse(user)
    }

    return (
        <div className="lg:w-full ">
            <Card
                id="sidebarContainer"
                className="lg:max-w-[17rem] lg:w-full hidden lg:flex md:fixed lg:fixed shadow-transparent w-screen h-screen ms-4 my-4 bg-transparent">
                <Button type={"button"} onClick={hiddenSidebar}
                        className="lg:hidden md:hidden p-2 bg-transparent text-black" variant="filled">
                    <XMarkIcon className="w-6 h-6"/>
                </Button>

                <Typography className="py-2 text-center" variant="h6" color="green">
                    <Avatar
                        alt="Usuario"
                        src={'/assets/logoAgro.jpg'}
                        className="w-9 h-9 ml-2"
                    /> Agro Servicios el Esfuerzo
                </Typography>
                <ActionUserComponent {...props}/>

                <List className="shadow-blue-gray-900/5 shadow-lg overflow-auto mt-4 rounded-lg lg:h-[calc(100vh-15rem)] menu-container">
                    {menuData.map((item, index) =>
                        // Si item tiene children entonces se muestra el boton de abrir menu como un acordeon
                        item.children ?
                            <Accordion
                                key={index}
                                open={openMenus[index] ?? false}
                                icon={
                                    <ChevronDownIcon
                                        strokeWidth={2.5}
                                        className={`mx-auto h-4 w-4 transition-transform ${openMenus[index] ? "rotate-180" : ""}`}
                                    />
                                }>
                                <ListItem className={validateClassName(menuData[index])} selected={openMenus[index]}>
                                    <AccordionHeader onClick={() => {
                                        handleOpenMenu(index)
                                    }} className="border-b-0 p-3">
                                        <ListItemPrefix>
                                            {item.icon}
                                        </ListItemPrefix>
                                        <Typography color="blue-gray" className="mr-auto font-normal">
                                            {item.title}
                                        </Typography>
                                    </AccordionHeader>
                                </ListItem>
                                <AccordionBody className="py-1">
                                    <List className="p-0">
                                        {item.children.map((child, index) =>
                                            <ListItem
                                                key={index}
                                                onClick={() => action(child._path)}
                                                className={validateClassName(child)}>
                                                <ListItemPrefix className="ml-4 mr-2"> {child.icon} </ListItemPrefix>
                                                {child.title}
                                            </ListItem>
                                        )}
                                    </List>
                                </AccordionBody>
                            </Accordion>
                            :
                            <ListItem
                                key={index}
                                onClick={() => action(item._path)}
                                className={validateClassName(item)}>
                                <ListItemPrefix className="mr-2"> {item.icon} </ListItemPrefix>
                                {item.title}
                            </ListItem>
                    )}
                </List>
            </Card>
        </div>
    );
}