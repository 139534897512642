import {Field, useField} from 'formik';
import {Typography, Button} from "@material-tailwind/react";
import {CheckboxComponent} from "../../../components/elements/Inputs";
import {SelectField} from "../../../components/elements/Inputs/SelectComponent";


export const ViewPermissionComponent = ({remove, push, isView, form, options, loading}) => {
  const [, meta, helpers] = useField('views');

  const addItem = async () => {
    push({
      view: null,
      create: false,
      edit: false,
      read: false,
    })
    await helpers.setTouched(true)
  }

  return (
    <div className="w-[35rem] mt-2">
      <div className="w-full flex gird grid-cols-2 justify-between mb-2 border-b pb-2">
        <Typography variant="h6" className="mb-0 col-span-1">
          Vistas:
        </Typography>
        <Button
          className="col-span-1"
          type="button"
          color="blue-gray"
          disabled={isView}
          onClick={addItem}
        >
          Agregar vista
        </Button>
      </div>

      {
        form.values?.views?.map((item, index) => (
          <div key={index}
               className="card px-4 py-2 flex flex-col justify-between border border-blue-gray-300 mt-1 rounded-md">
            <div className='flex flex-row justify-between'>
              <div>
                <Field
                  name={`views.${index}.view`}
                  component={SelectField}
                  disabled={isView}
                  options={options}
                  placeholder='Vista'
                  loading={loading}
                />
              </div>
              <div className="flex flex-row items-center">
                <CheckboxComponent
                  label={'Editar'}
                  disabled={isView}
                  checked={item.edit}
                  name={`views.${index}.edit`}
                />
                <CheckboxComponent
                  label={'Crear'}
                  disabled={isView}
                  checked={item.create}
                  name={`views.${index}.create`}
                />
                <CheckboxComponent
                  label={'Ver'}
                  disabled={isView}
                  checked={item.read}
                  name={`views.${index}.read`}
                />
              </div>
              <div className="mx-auto my-auto">
                <Button
                  color="red"
                  size='sm'
                  className=""
                  type="button"
                  disabled={isView}
                  onClick={() => remove(index)}
                >
                  x
                </Button>
              </div>
            </div>
            <div className='flex flex-row-reverse text-right'>
              {
                typeof meta.error == 'object' ?
                  <Typography className='text-red-500 text-[10px] font-normal text-right pr-8'>
                    {meta.error[index]?.view ?? ''}
                  </Typography> : ''
              }
            </div>
          </div>
        ))
      }
      <div className="mt-1 flex justify-end items-end content-end px-2">
        {
          (meta.error && typeof meta.error == 'string') ?
            <Typography className='text-red-500 text-[10px] font-normal '>
              {meta.error}
            </Typography> :
            ''
        }
      </div>

    </div>
  )
}
