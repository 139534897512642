import {Card, IconButton, Typography} from "@material-tailwind/react";
import {setMoney} from "../../../utils/shared";
import {PlusIcon} from "@heroicons/react/24/solid";
import {Field} from "formik";
import {SelectComponent} from "../../../components/elements/Inputs";
import {InputTable} from "../../../components/elements/Inputs/InputComponent";
import {AsyncSelectField} from "../../../components/elements/Inputs/SelectComponent";
import React from "react";

const TABLE_HEAD = ["#", "Bodega", "Producto", "Cantidad", "Precio", "Subtotal"];

export function PurchaseDetails({push, values, searchProduct, warehouse, errors}) {
  return (
    <Card className="h-full w-full rounded-none p-4" style={{flexBasis: '150px !important'}}>
      <div className="w-full flex flex-row justify-between">
        <Typography
          variant="h4"
          color="black"
          className="mb-1"
        >
          Detalle de compra
        </Typography>
        <IconButton>
          <PlusIcon className="w-5 h-5 " onClick={() => push({})}></PlusIcon>
        </IconButton>
      </div>
      <table className="w-full min-w-max table-auto text-left">
        <thead>
        <tr>
          {TABLE_HEAD.map((head, index) => {
            const isLast = index === TABLE_HEAD.length - 1;
            const classes = isLast ? "text-right" : "";

            return (
              <th
                key={head+index}
                className={`border-b border-blue-gray-100 px-2 py-3`}
              >
                <Typography
                  variant="small"
                  color="blue-gray"
                  className={`font-normal leading-none opacity-70 ${classes}`}
                >
                  {head}
                </Typography>
              </th>
            )
          })
          }
        </tr>
        </thead>
        <tbody>
        {values.purchase_detail.map((itemDetail, index) => {
          const classes = "px-2 py-0 border-b border-blue-gray-50";
          return (
            <tr key={index}>
              <td className={classes}>
                <Typography variant="small" color="black" className="opacity-90">
                  {index + 1}
                </Typography>
              </td>
              <td className={classes}>
                <SelectComponent
                  classSubContainer=""
                  isClearable={false}
                  hideError={true}
                  options={warehouse}
                  name={`purchase_detail.${index}.warehouse`}
                />
              </td>
              <td className={classes}>
                <Field
                  loadOptions={searchProduct}
                  placeholder="buscar.."
                  name={`purchase_detail.${index}.product_presentation`}
                  component={AsyncSelectField}
                />
              </td>
              <td className={classes}>
                <InputTable
                  maxLength={4}
                  name={`purchase_detail.${index}.quantity`}
                />
              </td>
              <td className={classes}>
                <InputTable
                  type="current"
                  maxLength={8}
                  name={`purchase_detail.${index}.price`}/>
              </td>
              <td className={classes + " text-justify"}>
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal text-black text-right"
                >
                  {setMoney(values.purchase_detail?.[index]?.quantity * values.purchase_detail?.[index]?.price)}
                </Typography>
              </td>
            </tr>
          );
        })}
        </tbody>
      </table>
    </Card>
  );
}