import React, {useEffect, useState} from "react";
import * as Yup from "yup";
import {Field, FieldArray, Form, Formik} from 'formik';
import {useNavigate, useParams} from "react-router-dom";
import {LoaderEllipsisComponent} from "../../../components/elements/Loader/LoaderComponent";
import {PrimaryButton, SecondaryButton} from "../../../components/elements/Inputs/ButtonsComponents";
import {PurchaseDetails} from "./PurchaseDetailComponent";
import api from "../../../js/api";
import {toastInfo} from "../../../js/toast";
import {
  CODE_ERROR_CATALOGS,
  setDateInput,
  setDatetimeForDjango,
  setMoney
} from "../../../utils/shared";
import {SelectField} from "../../../components/elements/Inputs/SelectComponent";
import {DateComponent, InputComponent} from "../../../components/elements/Inputs";
import {Card, Typography} from "@material-tailwind/react";


export function CreatePurchase(props) {
  const {loader} = props;

  const nav = useNavigate();
  const {id} = useParams();

  const handleSubmit = (formValue) => {
    formValue.total = 0
    if (id) {
      // props.update(id, formValue, nav)
    } else {
      formValue.date = setDatetimeForDjango(formValue.date)
      props.create(formValue, nav)
    }
  }

  return (
    <>
      <Card className="py-5 h-full px-4 w-full border shadow-lg">
        <div className="w-full h-full">
          <LoaderEllipsisComponent loader={loader}/>
          <FormItemPurchaseSearch handleSubmit={handleSubmit}/>
        </div>
      </Card>
    </>
  )
}

const ButtonsForm = ({goBack, isView, isUpdate, submitForm, isValid}) => {


  return (
    <div className="flex md:flex-row lg:flex-row flex-col justify-between gap-3 mt-6">
      <SecondaryButton
        type="button"
        text={'Regresar'}
        onClick={goBack}
      >
      </SecondaryButton>
      {
        !isView &&
        <PrimaryButton
          onClick={submitForm}
          text={isUpdate ? 'Actualizar' : 'Crear'}
          disabled={!isValid}
        >
        </PrimaryButton>
      }
    </div>
  )
}

const FormItemPurchaseSearch = ({handleSubmit}) => {
  const [didMount, setMount] = useState(false);
  const [suppliers, setSupplier] = useState(false);
  const [warehouse, setWarehouse] = useState(false);
  const [errorCatalogs, setErrorCatalogs] = useState(undefined);
  const nav = useNavigate();

  useEffect(() => {
    setMount(true);
  }, []);

  useEffect(() => {
    if (didMount) {
      api.get('supplier/options')
        .then((response) => {
          if (!response?.data?.length) {
            toastInfo('Sin proveedores')
            setErrorCatalogs(CODE_ERROR_CATALOGS[0].CODE)
          }
          setSupplier(response.data)
        })
        .finally(() => {
        })

      api.get('warehouse/options')
        .then((response) => {
          setWarehouse(response.data)
        })
        .finally(() => {
        })
    }
  }, [didMount]);

  useEffect(() => {
    setMount(true)
  }, [])

  const validationSchemaSearch = Yup.object().shape({
    date: Yup.string().required('Requerido'),
    purchase_detail: Yup.array()
      .of(
        Yup.object().shape({
          warehouse: Yup.number().required('Requerido'),
          product_presentation: Yup.object().required('Requerido'),
          quantity: Yup.number().required('Requerido'),
          price: Yup.number().required('Requerido'),
          // subtotal: Yup.number().required('Requerido'),
        })
      )
      .min(1, 'Mínimo un detalle')
      .required('Debe generar un detalle') // these constraints are shown if and only if inner constraints are satisfied
    ,
  });

  const loadOptions = async (inputValue, didMountComponent) => {
    // Realziamos la llamada a la API para obtener opciones pasandole el valor del input como parámetro
    const params = {
      name: inputValue,
    }
    if (didMountComponent) {
      const response = await api.get('product-presentation/search', params)
      return response.data
    }
  };

  const getTotal = (details) => {
    let totalPurchase = 0;
    if (details) {
      details.map((detail) => totalPurchase += detail.price * detail.quantity)
    }
    return setMoney(totalPurchase);
  }


  return (

    <Formik
      validationSchema={validationSchemaSearch}
      onSubmit={handleSubmit}
      validateOnMount={true}
      initialValues={{purchase_detail: [{}], suppler: 1, date: setDateInput(new Date())}}
      component={({values, isValid, errors, touched}) =>
        <Form
          name="formPurchase">
          <div className="w-[60%] mx-auto py-4">
            <Typography className="font-bold text-black" variant="h4">
              Registro de compra
            </Typography>
            <div className="pt-2 ">
              <div className="grid grid-cols-2 gap-4 justify-between mt-4">
                <div className='col-span-1'>
                  <Field
                    options={suppliers}
                    component={SelectField}
                    name={`supplier`}
                    label="Proveedor"
                  />
                </div>
                <div className='col-span-1 pt-1'>
                  <DateComponent
                    name={`date`}
                    type="date"
                    label="Fecha compra"
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4 justify-between">
                <div className='col-span-1'>
                  <InputComponent
                    name={`no_document`}
                    label="No. comprobante"
                  />
                </div>
                <div className='col-span-1 '>
                  <InputComponent
                    name={`total`}
                    label="Total"
                    value={getTotal(values?.purchase_detail)}
                    disabled
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4 justify-between">
                <div className='col-span-1'>
                  <InputComponent
                    type="textarea"
                    name={`observations`}
                    label="Nota"
                  />
                </div>
              </div>
            </div>
            {/*=========================================================================================*/}
            {/*=========================================================================================*/}
            {/*=========================================================================================*/}
            <FieldArray
              name="purchase_detail"
              validateOnChange={false}
              render={(helpers) => <PurchaseDetails
                {...helpers}
                values={values}
                errors={errors}
                touched={touched}
                warehouse={warehouse}
                searchProduct={loadOptions}
              />}
            />
            <ButtonsForm
              isUpdate={false}
              isView={false}
              isValid={isValid}
              goBack={() => nav('/compra')}
              submitForm={() => {
              }}
            />
          </div>
        </Form>
      }
    />

  )
}