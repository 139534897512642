import {createSlice} from '@reduxjs/toolkit'
import api from '../../api'
import {toastError, toastSuccess} from '../../toast'


export const userSlice = createSlice({
  name: 'user',
  initialState: {
    data: undefined,
    loader: false,
    loaderMe: false,
    isLogged: false,
  },
  reducers: {
    // PURE REDUCER
    setData: (state, action) => {
      state.data = action.payload
      if (action.payload) {
        localStorage.setItem('user', JSON.stringify(action.payload))
        let views = action.payload.views
        if (action.payload.views !== 'is_superuser'){
          views = JSON.stringify(action.payload.views)
        }
        localStorage.setItem('views', views)
      } else {
        localStorage.removeItem('views')
        localStorage.removeItem('user')
      }
    },
    setLoader: (state, action) => {
      state.loader = action.payload
    },
    setLoaderMe: (state, action) => {
      state.loaderMe = action.payload
    },
    setIsLogged: (state, action) => {
      state.isLogged = action.payload
    },
  },
  // extraReducers: (builder) => {
  //   // Add reducers for additional action types here, and handle loading state as needed
  //   builder.addCase(fetchUserById.fulfilled, (state, action) => {
  //     // Add user to the state array
  //     state.entities.push(action.payload)
  //   })
  // },

})


const userAction = userSlice.actions
// // EXTRA REDUCER
const getToken = () => (dispatch, store) => {
  return api.getToken()
}

const verifySession = (redirectFunc=null) => (dispatch, store) => {
  dispatch(userAction.setLoaderMe(true))
  const token = api.getToken()
  if (token && token.length > 4) {
    api.get('user/me')
      .then((response) => {
        dispatch(userAction.setData(response.data))
        // redirectFunc && redirectFunc("/")
      })
      .catch((reason) => {
        console.log('[error]', reason)
        api.removeToken()
        toastError(reason)
        redirectFunc && redirectFunc("/login")
      })
      .finally(() => {
        dispatch(userAction.setLoaderMe(false))
      })
  } else {
    redirectFunc && redirectFunc("/login")
  }
  dispatch(userAction.setLoaderMe(false))
}

const loginAction = (data, actionNav) => (dispatch, storage, handleAction) => {

  dispatch(userAction.setLoader(true))
  api.post('user/login', data)
    .then((response) => {
      dispatch(userAction.setData(response.data?.user))
      api.setToken(response.data?.token)
      actionNav("/")
      toastSuccess('Sesión iniciada correctamente')
    })
    .catch((reason) => {
      toastError(reason)
    })
    .finally(() => {
      dispatch(userAction.setLoader(false))
    })
}

const updateProfile = (data) => (dispatch) => {
  dispatch(userAction.setLoader(true))
  api.put(`user/${data.idUser}`, data)
    .then((response) => {
      toastSuccess(response)
      dispatch(verifySession())
    })
    .catch((reason) => {
      toastError(reason)
    })
    .finally(() => {
      dispatch(userAction.setLoader(false))
    })
}

const updateImgProfile = (file) => (dispatch, getStorage) => {
  let id = getStorage().user.data.id;
  const data = {photo: file}
  dispatch(userAction.setLoader(true))
  api.putFormData(`user/${id}/updatePhoto`, data)
    .then((response) => {
      toastSuccess(undefined, 'Imagen actualizada')
      dispatch(verifySession())
    })
    .catch((reason) => {
      toastError(undefined, 'La Imagen no fue actualizada')
    })
    .finally(() => {
      dispatch(userAction.setLoader(false))
    })
}


const logOut = (redirectAction) => (dispatch) => {
  dispatch(userAction.setLoader(true))
  api.post('user/logout',)
    .then((response) => {
      dispatch(userAction.setData(undefined))
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      localStorage.removeItem('views')
      redirectAction("/login")
      toastSuccess('La sesión se cerro con éxito')
    })
    .catch((reason) => {
      toastError('La sesión no se finalizo con éxito')
      console.log('[error logOutAction]', reason)
    })
    .finally(() => {
      dispatch(userAction.setLoader(false))
    })
}

const resetPassword = (data) => (dispatch) => {
  dispatch(userAction.setLoader(true))
  api.put("user/reset_password", data)
    .then((response) => {
      toastSuccess(response)
      dispatch(verifySession())
    })
    .catch((reason) => {
      toastError(reason)
    })
    .finally(() => {
      dispatch(userAction.setLoader(false))
    })
}

// Action creators are generated for each case reducer function
export const actions = {
  logOut,
  getToken,
  loginAction,
  updateProfile,
  verifySession,
  resetPassword,
  updateImgProfile,
  ...userSlice.actions,
}


export default userSlice.reducer
